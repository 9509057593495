<div class="row">
  <div class="col-md-6">
    <h2 mat-dialog-title>Terms and Conditions of Use</h2>
  </div>
  <div class="col-md-6 text-right">
    <button style="margin-right: 20px" mat-icon-button (click)="downloadPDF()">
      <mat-icon>download</mat-icon>
    </button>

    <button mat-icon-button (click)="onClose(false)">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<mat-dialog-content #termsContent id="termsContent">
  <div class="terms-container">
    <!-- <p *ngIf="data">{{ data.message }}</p> Display passed data -->
    <div
      style="
        font-family: Arial, sans-serif;
        line-height: 1.6;
        color: #333;
        max-width: 1000px;
        margin: 0 auto;
        padding: 20px;
      "
    >
      <div
        style="
          color: #000;
          font-size: 24px;
          text-align: center;
          font-weight: 600;
        "
      >
        TERMS AND CONDITIONS <br />
        OF USE INSTEP PORTAL ("Terms and Conditions of Use")
      </div>

      <section class="top-content" style="margin-bottom: 20px">

        <p style="font-size: 14px">
          These Terms and Conditions of Use of Stichting IDH (hereinafter
          <b>"IDH"</b>) (chamber of commerce number 53521129), established at
          Arthur van Schendelstraat 500 3511MH Utrecht, apply to the provision
          and use of INSTEP Portal (as defined below) as made available via the
          Website (as defined below).
        </p>

        <p style="font-size: 14px">
          You (being a representative of a Participant and/ or an Assessment
          Firm falling under the INSTEP Program) accept these Terms and
          Conditions of Use by indicating your acceptance of these Terms and
          Conditions of Use by ticking the box upon registering a User Account
          (as defined below) on the Website. If you do not agree with these
          Terms and Conditions of Use, please do not continue to create a User
          Account via the Website.
        </p>

        <p style="font-size: 14px">
          The reason IDH has developed the INSTEP Portal is because transparency
          of social-, environmental-, and building safety data is vital to
          identify, baseline, and track progress of sustainable manufacturing at
          Industrial Parks and Factories (as defined below). To this end, the
          INSTEP Program aims to communicate transparently to its direct
          stakeholders, the wider textile and apparel sector and the public
          about the impacts of the INSTEP Program on industrial park level
          located in a specific country. The main communication channel used to
          distribute such information is the INSTEP Portal. The INSTEP Portal is
          a data platform envisioned to monitor and show the impact of IDH's
          INSTEP Program and to advance data transparency on environmental and
          social performance in the textile and apparel industry.
        </p>

        <p style="font-size: 14px">
          The main data source for the INSTEP Portal are the baseline and
          monthly progress forms which are to be completed on the INSTEP Portal
          by the Assessment Firm performing the baseline assessment or a
          Participant filling in the monthly progress form. For the baseline
          assessment, the Industrial Park Sustainability Assessment (IPSA) (as
          defined below) is used, which is an environmental, social and building
          safety assessment of Industrial Park and Textile and Apparel Factories
          in Industrial Park settings. This assessment will be conducted at
          Industrial Parks and Textile and Apparel Factories that participate in
          the INSTEP Program. The INSTEP Portal will house this data and provide
          useful metrics for Industrial Parks and Textile and Apparel Factories,
          IDH staff, brands, government bodies, academia, and the general public
          to understand the INSTEP Program and its results.
        </p>
      </section>

      <section style="margin-bottom: 20px">
        <div
          style="
            color: #000;
            font-size: 16px;
            font-weight: 600;
            padding-bottom: 4px;
          "
        >
          1. Definitions and interpretation
        </div>
        <div style="margin-left: 20px">
          <div style="display: table; font-size: 14px">
            <span style="display: table-cell; width: 40px; vertical-align: top"
              >1.1.</span
            >
            <span style="display: table-cell"
              >The following definitions and rules of interpretation in this
              clause shall apply in the Terms of and Conditions of Use:</span
            >
          </div>

          <table
            style="width: 100%; border-collapse: collapse; margin-top: 15px"
          >
            <tr>
              <td
                style="
                  border: 1px solid #ddd;
                  padding: 10px;
                  vertical-align: top;
                "
              >
                <strong>Assessment Firm</strong>
              </td>
              <td style="border: 1px solid #ddd; padding: 10px">
                A professional organization responsible for conducting the IPSA
                assessment.
              </td>
            </tr>
            <tr>
              <td
                style="
                  border: 1px solid #ddd;
                  padding: 10px;
                  vertical-align: top;
                "
              >
                <strong>Assessment Firm Super User</strong>
              </td>
              <td style="border: 1px solid #ddd; padding: 10px">
                The representative of the Assessment Firm that is submitting
                data on behalf of Industrial Parks and Textile and/ or Apparel
                Factories the Assessment Firm in the INSTEP Portal via an User
                Account.
              </td>
            </tr>
            <tr>
              <td
                style="
                  border: 1px solid #ddd;
                  padding: 10px;
                  vertical-align: top;
                "
              >
                <strong>Collective Improvement Plan (CIP)</strong>
              </td>
              <td style="border: 1px solid #ddd; padding: 10px">
                A structured tool, in the form of an Excel template, used by the
                Industrial Park and/ or Textile and Apparel Factory to outline
                and document planned actions aimed at addressing the improvement
                opportunities identified through the IPSA.
              </td>
            </tr>
            <tr>
              <td
                style="
                  border: 1px solid #ddd;
                  padding: 10px;
                  vertical-align: top;
                "
              >
                <strong>Data</strong>
              </td>
              <td style="border: 1px solid #ddd; padding: 10px">
                Information captured during the IPSA assessment or information
                submitted directly to the INSTEP Portal via online forms.
              </td>
            </tr>
            <tr>
              <td
                style="
                  border: 1px solid #ddd;
                  padding: 10px;
                  vertical-align: top;
                "
              >
                <strong>Follow-up Assessment Reports</strong>
              </td>
              <td style="border: 1px solid #ddd; padding: 10px">
                Documents prepared by IDH that detail progress on the Collective
                Improvement Plan (CIP), based on visual observations and
                evaluations conducted by an IDH staff member during a follow-up
                visit. These reports provide insights into implementation status
                and areas requiring further attention.
              </td>
            </tr>
            <tr>
              <td
                style="
                  border: 1px solid #ddd;
                  padding: 10px;
                  vertical-align: top;
                "
              >
                <strong>INSTEP/ INSTEP Program</strong>
              </td>
              <td style="border: 1px solid #ddd; padding: 10px">
                IDH's Sustainable Textile and Apparel Parks (INSTEP) program is
                a multi-stakeholder program including, but not limited to,
                buyers, brands, retailers, industrial parks, and its tenants,
                manufacturing facilities, industry associations, and other key
                stakeholders in the textile and apparel sector with the overall
                purpose to pre-competitively make the textile and apparel supply
                chain more sustainable. The Program specifically focusses on
                building environmentally and socially effective, efficient, and
                sustainable Industrial Parks and Textile and Apparel Factories.
              </td>
            </tr>
            <tr>
              <td
                style="
                  border: 1px solid #ddd;
                  padding: 10px;
                  vertical-align: top;
                "
              >
                <strong>Industrial Park Sustainability Assessment </strong>
              </td>
              <td style="border: 1px solid #ddd; padding: 10px">
                The Industrial Park Sustainability Assessment (IPSA) is an
                assessment that measures the social, environmental, and building
                safety baseline status of Industrial Parks and Textile and
                Apparel Factories within Industrial Park settings. The
                assessment is used to identify best practices as well as areas
                for improvement. Through transparency and collective
                interventions, it aims to support continuous improvement within
                the sector, and transformation of Industrial Parks and Textile
                and Apparel Factories.
              </td>
            </tr>
            <tr>
              <td
                style="
                  border: 1px solid #ddd;
                  padding: 10px;
                  vertical-align: top;
                "
              >
                <strong>(IPSA) Excel Report </strong>
              </td>
              <td style="border: 1px solid #ddd; padding: 10px">
                The IPSA Excel Report is the
                full assessment report which is completed by the Assessment Firm
                offline during and after the assessment.
              </td>
            </tr>
            <tr>
              <td
                style="
                  border: 1px solid #ddd;
                  padding: 10px;
                  vertical-align: top;
                "
              >
                <strong>(IPSA) PDF Report </strong>
              </td>
              <td style="border: 1px solid #ddd; padding: 10px">
                The IPSA PDF
                Report is a PDF version of the (IPSA) Excel Report, created by
                the Assessment Firm online on the INSTEP Portal. The PDF report
                is more user-friendly and easier to read compared to the (IPSA)
                Excel report.
              </td>
            </tr>
            <tr>
              <td
                style="
                  border: 1px solid #ddd;
                  padding: 10px;
                  vertical-align: top;
                "
              >
                <strong>(IPSA) Report Summary </strong>
              </td>
              <td style="border: 1px solid #ddd; padding: 10px">
                The IPSA Report Summary is a Word or
                PDF document that summarizes the findings of the IPSA. It
                provides an overview of the key best practices and areas for
                improvement in a readable format.
              </td>
            </tr>
            <tr>
              <td
                style="
                  border: 1px solid #ddd;
                  padding: 10px;
                  vertical-align: top;
                "
              >
                <strong>INSTEP Portal Data Indicators</strong>
              </td>
              <td style="border: 1px solid #ddd; padding: 10px">
                The INSTEP Portal Data Indicators are data points self-reported
                by i Industrial Parks and Textile and Apparel Factories through
                data forms submitted by them on the INSTEP Portal.
              </td>
            </tr>
            <tr>
              <td
                style="
                  border: 1px solid #ddd;
                  padding: 10px;
                  vertical-align: top;
                "
              >
                <strong>Participant</strong>
              </td>
              <td style="border: 1px solid #ddd; padding: 10px">
                An Industrial Park or Textile and Apparel Factory that
                participates in the IPSA.
              </td>
            </tr>
            <tr>
              <td
                style="
                  border: 1px solid #ddd;
                  padding: 10px;
                  vertical-align: top;
                "
              >
                <strong>Participant Super User</strong>
              </td>
              <td style="border: 1px solid #ddd; padding: 10px">
                The representative of the Participant that is submitting data on
                behalf of the Participant in the INSTEP Portal via a User
                Account.
              </td>
            </tr>
            <tr>
              <td
                style="
                  border: 1px solid #ddd;
                  padding: 10px;
                  vertical-align: top;
                "
              >
                <strong>User Account</strong>
              </td>
              <td style="border: 1px solid #ddd; padding: 10px">
                Being the account of either a representative (Super User) of a
                Participant or of an Assessment Firm which- depending on which
                role you have- gives dedicated rights to submit Data in the
                allocated fields in the INSTEP Portal on behalf of the
                Participant or the Assessment Firm.
              </td>
            </tr>
            <tr>
              <td
                style="
                  border: 1px solid #ddd;
                  padding: 10px;
                  vertical-align: top;
                "
              >
                <strong>Website</strong>
              </td>
              <td style="border: 1px solid #ddd; padding: 10px">
                <a href="https://www.idh-instep.com" style="color: #0066cc"
                  >https://www.idh-instep.com</a
                >
                and all of its subsites.
              </td>
            </tr>
          </table>
        </div>
      </section>

      <section style="margin-bottom: 20px">
        <div
          style="
            color: #000;
            font-size: 16px;
            font-weight: 600;
            padding-bottom: 4px;
          "
        >
          2. Scope
        </div>
        <div style="margin-left: 20px">
          <div style="display: table; font-size: 14px">
            <span style="display: table-cell; width: 40px; vertical-align: top"
              >2.1.</span
            >
            <span style="display: table-cell"
              >These Terms of Use apply to all INSTEP Participants, including
              but not limited to Industrial Parks, Textile and Apparel Factories
              and Assessment Firms.</span
            >
          </div>
        </div>
      </section>

      <section style="margin-bottom: 20px">
        <div
          style="
            color: #000;
            font-size: 16px;
            font-weight: 600;
            padding-bottom: 4px;
          "
        >
          3. Access to the INSTEP Portal
        </div>
        <div style="margin-left: 20px">
          <div style="display: table; font-size: 14px">
            <span style="display: table-cell; width: 40px; vertical-align: top"
              >3.1.</span
            >
            <span style="display: table-cell"
              >The INSTEP Portal can be accessed via the Website and in order be
              able to submit any Data to the INSTEP Portal, the Assessment Firm
              and Participant must register an account. Upon registration, the
              Assessment Firm and the Participant will receive a link per e-mail
              to activate its User Account.</span
            >
          </div>

          <div style="display: table; font-size: 14px">
            <span style="display: table-cell; width: 40px; vertical-align: top"
              >3.2.</span
            >
            <span style="display: table-cell"
              >Where the Participant submits Data of its employer, Participant
              hereby represents and warrants that it has the authority to bind
              the employer to these Terms and Conditions of Use.</span
            >
          </div>

          <div style="display: table; font-size: 14px">
            <span style="display: table-cell; width: 40px; vertical-align: top"
              >3.3.</span
            >
            <span style="display: table-cell"
              >The Assessment Firm and the Participant shall ensure access and
              use of the INSTEP Portal in accordance with these Terms and
              Conditions of Use.</span
            >
          </div>

          <div style="display: table; font-size: 14px">
            <span style="display: table-cell; width: 40px; vertical-align: top"
              >3.4.</span
            >
            <span style="display: table-cell"
              >IDH is entitled to (partially) block the access to the INSTEP
              Portal at any time if:</span
            >
          </div>
          <ol type="1" style="margin-left: 20px; font-size: 14px">
            <li>
              an Assessment Firm or Participant does not use INSTEP Portal in
              conformity with these Terms and Conditions of Use;
            </li>
            <li>
              It is necessary to block the access of a user to protect the
              security and integrity of the INSTEP Portal. IDH is, for example,
              allowed to block the User Account in the event of:
            </li>
            <li>multiple entries of an incorrect username and/or password,</li>
            <li>suspicion of (providing) unauthorized access; or</li>
            <li>
              if there are disruptions in the provision of the INSTEP Portal
              caused by the INSTEP Portal.
            </li>
          </ol>

          <div style="display: table; font-size: 14px">
            <span style="display: table-cell; width: 40px; vertical-align: top"
              >3.5.</span
            >
            <span style="display: table-cell"
              >The right of the Assessment Firm and/ or the Participant to use
              the INSTEP Portal ends with immediate effect at the moment IDH
              blocks the access of the user.</span
            >
          </div>
        </div>
      </section>

      <section style="margin-bottom: 20px">
        <div
          style="
            color: #000;
            font-size: 16px;
            font-weight: 600;
            padding-bottom: 4px;
          "
        >
          4. Use of the INSTEP Portal
        </div>
        <div style="margin-left: 20px">
          <div style="display: table; font-size: 14px">
            <span style="display: table-cell; width: 40px; vertical-align: top"
              >4.1.</span
            >
            <span style="display: table-cell"
              >The Participant Super Users and Assessment Firm Super Users are
              authorized to create a User Account.</span
            >
          </div>
          <div style="display: table; font-size: 14px">
            <span style="display: table-cell; width: 40px; vertical-align: top"
              >4.2.</span
            >
            <span style="display: table-cell"
              >IDH may assume and is not obliged to verify that the Assessment
              Firm Super User and the Participant Super User are authorized to
              submit Data on behalf of the Assessment Firm/ Participant.</span
            >
          </div>
          <div style="display: table; font-size: 14px">
            <span style="display: table-cell; width: 40px; vertical-align: top"
              >4.3.</span
            >
            <span style="display: table-cell"
              >The Assessment Firm and the Participant must ensure that the
              Assessment Firm Super User and the Participant Super User will act
              in accordance with these Terms and Conditions of Use.</span
            >
          </div>
          <div style="display: table; font-size: 14px">
            <span style="display: table-cell; width: 40px; vertical-align: top"
              >4.4.</span
            >
            <span style="display: table-cell"
              >The Participant Super User and the Assessment Firm Super User
              must be 18 years or older or at least the age of majority in the
              jurisdiction where the Participant or the Assessment Firm will use
              the INSTEP Portal.</span
            >
          </div>
          <div style="display: table; font-size: 14px">
            <span style="display: table-cell; width: 40px; vertical-align: top"
              >4.5.</span
            >
            <span style="display: table-cell"
              >Participants and Assessment Firms acknowledge and agree that they
              are responsible for properly safeguarding and keeping confidential
              the login details the INSTEP Portal. If the Assessment Firm or
              Participant knows or suspects that an unauthorized party is aware
              of their login details, please contact us immediately at the email
              address below.</span
            >
          </div>
          <div style="display: table; font-size: 14px">
            <span style="display: table-cell; width: 40px; vertical-align: top"
              >4.6.</span
            >
            <span style="display: table-cell"
              >The Participant Super User and the Assessment Firm Super User may
              only use the dashboards and/or facility reports for the following
              purposes:</span
            >
          </div>
          <ol type="a" style="margin-left: 20px; font-size: 14px">
            <li>internal learning.</li>
            <li>
              facilitating the development of effective strategies based on the
              environmental, social and building safety assessment of the
              Participant.
            </li>
            <li>
              communication about (addressing) the INSTEP Program and the IPSA.
            </li>
            <li>
              reporting and/or providing transparency on environmental, social
              and building safety of the Participant and its progress.
            </li>
          </ol>

            <span style="margin-left: 33px; font-size: 14px"
              >4.6. The Participant and/or the Assessment Firm may not:</span
            >

          <ol type="a" style="margin-left: 20px; font-size: 14px">

            <li>
              distribute, sublicense, lease, sell, commercialize, rent, loan, or
              otherwise transfer its User Account to any third party.
            </li>
            <li>
              use the INSTEP Portal for any other purposes than set out in these
              Terms and Conditions of Use.
            </li>
          </ol>

          <div style="display: table; font-size: 14px">
            <span style="display: table-cell; width: 40px; vertical-align: top"
              >4.7.</span
            >
            <span style="display: table-cell"
              >The Assessment Firm and the Participant are strictly prohibited
              to use the INSTEP Portal for actions and/or behaviors that are
              unlawful under applicable laws and regulations, violate these
              Terms and Conditions of Use, and/or any other use that is contrary
              to public order and good morals. This includes, but is not limited
              to the following actions and behaviours:</span
            >
          </div>
          <ol type="a" style="margin-left: 20px; font-size: 14px">
            <li>
              The use of the INSTEP Portal in such a way that harms products
              and/or services of IDH, its other customers or third parties;
            </li>
            <li>
              Circumventing or removing technical provisions intended to protect
              the INSTEP Portal (or having them circumvented or removed);
            </li>
            <li>
              Transmitting software or other elements such as viruses, bugs,
              Trojan horses, which may overwhelm systems of the INSTEP Portal or
              of third parties, "mail bombing", denial of service attacks, the
              creation or management of botnets, or any other infection,
              including but not limited to executable codes or files that may be
              used to access the software or hardware of IDH or third parties,
              modify, delete or damage them, or the electronic documents and
              files stored in such system, as well as any other program or
              element with purposes or effects that are unlawful, prohibited or
              detrimental to the interest and rights of third parties, and IDH
              does not accept any liability that may result from the foregoing;
            </li>
            <li>
              Infringing upon copyrighted works, rights on databases or
              otherwise violating the intellectual property rights of us, our
              clients or third parties;
            </li>
            <li>
              Reproducing, duplicating, copying, selling, reselling or
              exploiting all or part of the INSTEP Portal and the information
              offered through the INSTEP Portal without the express prior
              consent of IDH;
            </li>
            <li>
              Any use which is defamatory, threatening, intimidating or which
              could be classed as harassment; or
            </li>
            <li>
              Posting and transmitting any content that contains or is obscene,
              profane or contains abusive language or material; contains
              pornographic material (that is text, pictures, films, video clips
              or a sexually explicit or arousing nature); contains offensive or
              derogatory images regarding sex, race, religion, colour, origin,
              age, physical or mental disability, medical condition or sexual
              orientation; contains forged or misrepresented message headers,
              whether in whole or in part, to mask the originator of the
              message; invades another's privacy or is otherwise unlawful or
              inappropriate.
            </li>
          </ol>
        </div>
      </section>

      <section style="margin-bottom: 20px">
        <div
          style="
            color: #000;
            font-size: 16px;
            font-weight: 600;
            padding-bottom: 4px;
          "
        >
          5. Public Information on the INSTEP Portal
        </div>
        <div style="margin-left: 20px">
          <div style="display: table; font-size: 14px">
            <span style="display: table-cell; width: 40px; vertical-align: top"
              >5.1.</span
            >
            <span style="display: table-cell"
              >The Participant hereby allows IDH to publish the non-personal
              data listed below on the INSTEP Portal and hereby gives its
              consent that these will be visible to the public domain via the
              Website:
            </span>
          </div>
          <ol type="a" style="margin-left: 20px; font-size: 14px">
            <li>Industrial Park Sustainability Assessment (IPSA) Report</li>
            <li>
              Industrial Park Sustainability Assessment (IPSA) Report Summary
            </li>
            <li>Collective Improvement Plan (CIP)</li>
            <li>Follow-up Assessment Reports</li>
            <li>Data from INSTEP Portal Baseline and Monthly Progress Forms</li>
          </ol>
        </div>
      </section>

      <section style="margin-bottom: 20px">
        <div
          style="
            color: #000;
            font-size: 16px;
            font-weight: 600;
            padding-bottom: 4px;
          "
        >
          6. Availability, accuracy and information on the INSTEP Portal
        </div>
        <div style="margin-left: 20px">
          <div style="display: table; font-size: 14px">
            <span style="display: table-cell; width: 40px; vertical-align: top"
              >6.1.</span
            >
            <span style="display: table-cell"
              >IDH strives to provide reasonable availability of the INSTEP
              Portal and its content. However, failures, limitations, defects
              and interruptions may occur and are not a breach. IDH will strive
              to restore the availability as soon as possible.</span
            >
          </div>
          <div style="display: table; font-size: 14px">
            <span style="display: table-cell; width: 40px; vertical-align: top"
              >6.2.</span
            >
            <span style="display: table-cell"
              >The Data is dependent and based on the information provided by
              the Assessment Firm and the Participants. All Data provided by and
              contained on the Website and the INSTEP Portal are provided on an
              "as is'', ''with all faults'', and ''as available'' basis and
              could potentially entail incorrect or incomplete information. IDH
              is not responsible for information and content provided by and
              contained in Website and the INSTEP Portal and has no control over
              this information and content. For this reason, IDH is not
              responsible and liable for the information and content and
              availability of such information and content.</span
            >
          </div>
          <div style="display: table; font-size: 14px">
            <span style="display: table-cell; width: 40px; vertical-align: top"
              >6.3.</span
            >
            <span style="display: table-cell"
              >IDH strives to stimulate the Assessment Firms and the
              Participants to provide the correct information, but does not
              provide any representation, guarantee or warranty of any kind as
              to the accuracy, validity, completeness, or timeliness of
              information contained in or provided through the INSTEP Portal and
              Website.</span
            >
          </div>
          <div style="display: table; font-size: 14px">
            <span style="display: table-cell; width: 40px; vertical-align: top"
              >6.4.</span
            >
            <span style="display: table-cell"
              >The Participant and the Assessment Firm expressly agree that the
              use of the INSTEP Portal, including the submission of any Data, is
              at its sole risk. To the fullest extent permitted by law, IDH
              assumes no responsibility or liability of any kind for any errors,
              omissions, faults, inaccuracies in the information provided by and
              contained in the INSTEP Portal. Furthermore, none of the
              contributors, administrators, or anyone else connected with the
              INSTEP Portal in any way whatsoever can be responsible for the
              appearance of any inaccurate information or for your use of the
              information contained in or provided through the INSTEP Portal
            </span>
          </div>
          <div style="display: table; font-size: 14px">
            <span style="display: table-cell; width: 40px; vertical-align: top"
              >6.5.</span
            >
            <span style="display: table-cell"
              >The information contained in and provided by the INSTEP Portal is
              not intended to be a certification or anything of that kind. If a
              Participants needs specific certifications, they should seek a
              professional who is licensed or knowledgeable in that area.</span
            >
          </div>
        </div>
      </section>

      <section style="margin-bottom: 20px">
        <div
          style="
            color: #000;
            font-size: 16px;
            font-weight: 600;
            padding-bottom: 4px;
          "
        >
          7. Intellectual property
        </div>
        <div style="margin-left: 20px">
          <div style="display: table; font-size: 14px">
            <span style="display: table-cell; width: 40px; vertical-align: top"
              >7.1.</span
            >
            <span style="display: table-cell"
              >Any intellectual property rights to the (content on the) INSTEP
              Portal– including but not limed to copyrights, trademark rights
              and databank law which are vested in, contained in or arising from
              (parts of) the INSTEP Portal are – and shall remain – vested in
              IDH.</span
            >
          </div>
          <div style="display: table; font-size: 14px">
            <span style="display: table-cell; width: 40px; vertical-align: top"
              >7.2.</span
            >
            <span style="display: table-cell"
              >Insofar the Participant uploads Data or other content on the
              INSTEP Portal, the Participant will hereby, on behalf of itself,
              grant IDH and Affiliates a royalty-free, non-exclusive license to
              use, copy, share, modify, distribute, sell, exploit, publish and
              process the intellectual property rights contained in or arising
              from (parts of) the Data to further IDH's and Affiliates'
              endeavors in accordance with applicable (privacy) laws.
            </span>
          </div>
          <div style="display: table; font-size: 14px">
            <span style="display: table-cell; width: 40px; vertical-align: top"
              >7.3.</span
            >
            <span style="display: table-cell"
              >The Participant is liable for and shall indemnify and hold IDH
              and Affiliates harmless against all loss or damage suffered or
              incurred by us as a result of any claim or action by any third
              party for infringement of any intellectual property rights in
              connection with the (use of the) Data or other content uploaded by
              the Participant, the Assessment Firm or IDH.</span
            >
          </div>
          <div style="display: table; font-size: 14px">
            <span style="display: table-cell; width: 40px; vertical-align: top"
              >7.4.</span
            >
            <span style="display: table-cell"
              >The Participant can terminate the license under clause 7.2. by
              deleting such Data or content from the INSTEP Portal, except (a)
              to the extent such Data and/or other content is shared with third
              parties (see clause 7.3) or (b) for the reasonable time it takes
              to remove such Data and/or content from backups and other
              systems.</span
            >
          </div>
        </div>
      </section>

      <section style="margin-bottom: 20px">
        <div
          style="
            color: #000;
            font-size: 16px;
            font-weight: 600;
            padding-bottom: 4px;
          "
        >
          8. Permission to share aggregated and anonymized Data
        </div>
        <div style="margin-left: 20px">
          <div style="display: table; font-size: 14px">
            <span style="display: table-cell; width: 40px; vertical-align: top"
              >8.1.</span
            >
            <span style="display: table-cell"
              >Insofar a Participant or an Assessment Firm uploads Data or other
              content on the INSTEP Portal, the Participant and the Assessment
              Firm hereby, grants IDH and Affiliates permission to use, copy,
              modify, distribute, publish, share and process (parts of) the Data
              to further IDH's and Affiliates' endeavours, in accordance with
              applicable (privacy) laws.</span
            >
          </div>
          <div style="display: table; font-size: 14px">
            <span style="display: table-cell; width: 40px; vertical-align: top"
              >8.2.</span
            >
            <span style="display: table-cell"
              >In addition to the above, the Participant and the Assessment Firm
              hereby, grants IDH permission to use, copy, modify, distribute,
              publish, share and process (parts of) the Data in an aggregated
              and anonymized (stripped of any personally identifiable
              information) manner to further IDH's endeavours aimed towards
              informing industry stakeholders about social and environmental
              challenges and best practices in Industrial Parks and Textile and
              Apparel Factories, in accordance with applicable (privacy) laws.
            </span>
          </div>
          <div style="display: table; font-size: 14px">
            <span style="display: table-cell; width: 40px; vertical-align: top"
              >8.3.</span
            >
            <span style="display: table-cell"
              >In particular, IDH and Affiliates may publish, and/or share
              informative reports and analyses based on aggregated and
              anonymized data in an effort to inform the public, to raise
              awareness about social and environmental challenges and best
              practices in Industrial Parks and Textile and Apparel Factories
              and to provide valuable insights into social and environmental
              trends and patterns. By sharing such insights, IDH aims to foster
              conversations and encourage positive social and environmental
              changes in the textile and apparel sector.</span
            >
          </div>
        </div>
      </section>

      <section style="margin-bottom: 20px">
        <div
          style="
            color: #000;
            font-size: 16px;
            font-weight: 600;
            padding-bottom: 4px;
          "
        >
          9. Privacy
        </div>
        <div style="margin-left: 20px">
          <div style="display: table; font-size: 14px">
            <span style="display: table-cell; width: 40px; vertical-align: top"
              >9.1.</span
            >
            <span style="display: table-cell"
              >IDH is data controller in relation to the personal data of the
              Participant and the Assessment Firm that it processes via the
              INSTEP Portal and will process such data in accordance with the
              privacy statement:
              <a href="https://www.idhsustainabletrade.com/privacy-statement/"
                >https://www.idhsustainabletrade.com/privacy-statement/</a
              >.
            </span>
          </div>
          <div style="display: table; font-size: 14px">
            <span style="display: table-cell; width: 40px; vertical-align: top"
              >9.2.</span
            >
            <span style="display: table-cell"
              >If the Participant or the Assessment Firm processes personal data
              via the INSTEP Portal, it shall assess and ensure that such
              personal data can be processed via the INSTEP Portal in accordance
              with applicable laws and regulations and, if applicable, the Terms
              and Conditions of Use.</span
            >
          </div>
        </div>
      </section>

      <section style="margin-bottom: 20px">
        <div
          style="
            color: #000;
            font-size: 16px;
            font-weight: 600;
            padding-bottom: 4px;
          "
        >
          10. Liability
        </div>
        <div style="margin-left: 20px">
          <div style="display: table; font-size: 14px">
            <span style="display: table-cell; width: 40px; vertical-align: top"
              >10.1.</span
            >
            <span style="display: table-cell"
              >To the fullest extent permitted by applicable law, IDH will not
              be liable for any damages arising out of or in connection
              with:</span
            >
          </div>
          <ol type="a" style="margin-left: 20px; font-size: 14px">
            <li>
              with the Participants and the Assessment Firm's access and/or use
              of the INSTEP Portal;
            </li>
            <li>
              the Data and content provided via the INSTEP Portal by the
              Participants and Assessment Firms;
            </li>
            <li>
              any errors or, incomplete, erroneous information, malfunctions or
              interruptions in the INSTEP portal, Data, Website and/or other
              content.
            </li>
          </ol>

          <div style="display: table; font-size: 14px">
            <span style="display: table-cell; width: 40px; vertical-align: top"
              >10.2.</span
            >
            <span style="display: table-cell"
              >The exclusions and limitations on liability set out in this
              clause 10 do not apply to liability arising in connection with
              death or personal injury resulting from a party's negligence, or
              that party's fraud, willful default or gross negligence (or a
              party's directors, officers, employees, subcontractor and
              agents).</span
            >
          </div>
        </div>
      </section>

      <section style="margin-bottom: 20px">
        <div
          style="
            color: #000;
            font-size: 16px;
            font-weight: 600;
            padding-bottom: 4px;
          "
        >
          11. Legal access
        </div>
        <div style="margin-left: 20px">
          <div style="display: table; font-size: 14px">
            <span style="display: table-cell; width: 40px; vertical-align: top"
              >11.1.</span
            >
            <span style="display: table-cell"
              >The INSTEP Portal is built on Amazon Web Services (AWS). The laws in your country or jurisdiction may or may not allow access to domains owned by Amazon Web Services, and therefore to the INSTEP Portal. IDH does not encourage the violation of any laws and cannot be held responsible for any violations or circumvention of such laws.</span
            >
          </div>
        </div>
      </section>

      <section style="margin-bottom: 20px">
        <div
          style="
            color: #000;
            font-size: 16px;
            font-weight: 600;
            padding-bottom: 4px;
          "
        >
          12. Termination
        </div>
        <div style="margin-left: 20px">
          <div style="display: table; font-size: 14px">
            <span style="display: table-cell; width: 40px; vertical-align: top"
              >12.1.</span
            >
            <span style="display: table-cell"
              >The Participant and the Assessment Firm may terminate an account
              at any time by emailing info@idh-instep.com and following the
              specific instructions indicated in the response by IDH.</span
            >
          </div>
          <div style="display: table; font-size: 14px">
            <span style="display: table-cell; width: 40px; vertical-align: top"
              >12.2.</span
            >
            <span style="display: table-cell"
              >Termination shall not affect the following clauses which shall
              continue to apply after termination: 7 (Intellectual Property), 8
              (Permission to share aggregated and anonymized Data), 10
              (Liability), 12 (Termination) and 13 (Applicable Law and
              Jurisdiction).</span
            >
          </div>
          <div style="display: table; font-size: 14px">
            <span style="display: table-cell; width: 40px; vertical-align: top"
              >12.3.</span
            >
            <span style="display: table-cell"
              >After termination all Data collected by the use of the INSTEP
              Portal will be deleted by IDH. Insofar the Data is part of
              pre-existing reports or aggregate reports, this Data will remain
              part of such reports. For the avoidance of doubt, this Data will
              not be part of reports created after the termination of the
              account.</span
            >
          </div>
        </div>
      </section>

      <section style="margin-bottom: 20px">
        <div
          style="
            color: #000;
            font-size: 16px;
            font-weight: 600;
            padding-bottom: 4px;
          "
        >
          13. Applicable Law and Dispute Resolution
        </div>
        <div style="margin-left: 20px">
          <div style="display: table; font-size: 14px">
            <span style="display: table-cell; width: 40px; vertical-align: top"
              >13.1.</span
            >
            <span style="display: table-cell"
              >The Terms and Conditions of Use are governed by and construed in
              accordance with the laws of the Netherlands.
            </span>
          </div>
          <div style="display: table; font-size: 14px">
            <span style="display: table-cell; width: 40px; vertical-align: top"
              >13.2.</span
            >
            <span style="display: table-cell"
              >Any and all disputes that arise out of or in connection with the
              Terms and Conditions of Use shall be exclusively submitted to the
              competent court of Midden-Nederland, the Netherlands</span
            >
          </div>
        </div>
      </section>

      <section style="margin-bottom: 20px">
        <div
          style="
            color: #000;
            font-size: 16px;
            font-weight: 600;
            padding-bottom: 4px;
          "
        >
          14. Severability
        </div>
        <div style="margin-left: 20px">
          <div style="display: table; font-size: 14px">
            <span style="display: table-cell; width: 40px; vertical-align: top"
              >14.1.</span
            >
            <span style="display: table-cell"
              >If it turns out that a particular clause is not enforceable, this
              will not affect any other clauses.</span
            >
          </div>
        </div>
      </section>

      <section style="margin-bottom: 20px">
        <div
          style="
            color: #000;
            font-size: 16px;
            font-weight: 600;
            padding-bottom: 4px;
          "
        >
          15. Questions
        </div>
        <div style="margin-left: 20px">
          <div style="display: table; font-size: 14px">
            <span style="display: table-cell; width: 40px; vertical-align: top"
              >15.1.</span
            >
            <span style="display: table-cell"
              >Questions about the Terms and Conditions of Use should be sent to <a href="info@idh-instep.com ">info@idh-instep.com</a>
              .
            </span>
          </div>
        </div>
      </section>

    </div>

    <span
      >By accepting this, I declare that I am authorized to represent my company
      and hereby agree to the Terms of Use of the INSTEP Portal on behalf of my
      company.</span
    >
  </div>
  <mat-dialog-actions>
    <button class="accept-btn text-white" mat-button (click)="onClose(true)">
      Accept
    </button>
  </mat-dialog-actions>
</mat-dialog-content>
