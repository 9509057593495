import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { UserManagementServiceService } from '../user-management-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { SnackBarComponent } from 'src/app/shared/snack-bar/snack-bar.component';
import { ActivatedRoute , Router} from '@angular/router';
import { MatCheckboxChange } from '@angular/material/checkbox';


@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {
  public userForm !: FormGroup ;
  countrys :any = [];
  ipNames :any = [];
  factoryNames :any = [];
  userRoles :any = [];
  userId: string = "";
  adminData: any = JSON.parse(sessionStorage.getItem('userdata')!);
  showCountryDropdown: boolean = false;
  showIPdropdown: boolean = false;
  showFactoryDropdown: boolean = false;
  selectedCountry: any;

  countryCodeArray = [
    {
      name: 'Ethiopia',
      id: '5',
      code: '251'
    },
    {
      name: 'Vietnam',
      id: '6',
      code: '84'
    },
    {
      name: 'Kenya',
      id: '4',
      code: '254'
    },
  ];
  userAllData: any;
  userEmailId: string = "";
  currentMode: any = 'edit';
  selectedLevel: any;
 
  constructor(
    private formBuilder: FormBuilder,
    private userManagementService: UserManagementServiceService,
    private snackBar:MatSnackBar,
    private route : ActivatedRoute,
    private router : Router,
  ) { 
    this.createUserForm();
    this.getUserRoleDetails();
    this.getCountryDetails();
  }

  @ViewChild('userRole') select: MatSelect | undefined;

  preventKeyboardInput(event: KeyboardEvent): void {
    if(event)
    return
    // event.preventDefault();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      console.log("allparamss",queryParams);
      this.currentMode = queryParams?.mode;
      
    })
    this.userId = this.route.snapshot.params.id;
    if(this.userId){
      setTimeout(() => {
        this.prifieldMethod(this.userId)
      }, 1000);
    }
  }
  createUserForm():void {
    this.userForm = this.formBuilder.group({
      userName: ["",Validators.required],
        email: ['', [Validators.required, Validators.email]],
        mobile: [""],
        userRole: ['',Validators.required],
        country_code: [""],
        // password: ["", Validators.required]
        ipName: [""],
        countryName: [''],
        factoryName: [''],
        superUser: [false]
    });
}

  async getCountryDetails(){
   await this.userManagementService.countryDetails().subscribe((res:any) => {
      if(res.success){
        if(this.adminData?.userlevel_id !=1){
          res.data = res.data.filter((response:any) => this.adminData?.country_id ? response.id == this.adminData?.country_id : true)
        }
        this.countrys = res.data;
        // this.openSnackBar([res?.message || 'Success !'] , '','success');
      }
    },(error:any)=>{
      this.openSnackBar([error?.message || 'Failed !'] , '');
    })

  }
  async getIpNameDetails(data:any = [], selectedCountryId:string, isFactory:boolean = false){
    console.log(data, "123");
    
   await this.userManagementService.ipNameDetails(selectedCountryId).subscribe((res:any) => {
      if(res?.success){
        
        if(this.adminData?.userlevel_id !=1 ){
          res.data = res.data.filter((response:any) => this.adminData?.industrialpark_id ? response.id == this.adminData?.industrialpark_id : true)
          let ipName = res.data.find((industrial_park:any) => industrial_park.id == this.adminData?.industrialpark_id);
            if(ipName){
            this.userForm.patchValue({
              ipName: ipName,
            })
            if(isFactory){
              this.getFactoryNameDetails([],ipName?.id, isFactory)
            }
          }

          
          console.log("section", ipName, this.userForm)
          
        }

        if(this.countrys?.length){
          this.ipNames = res?.data;
        }
        
        // this.openSnackBar([res?.message || 'Success !'] , '','success');
      }
    },(error:any)=>{
      this.openSnackBar([error?.message || 'Failed !'] , '');
    },()=>{
      if(this.userId && data?.length){
        if(data[0]['country_id'] && data[0]['industrialpark_id'] && !data[0]['factory_id']){
          this.dropdownDataPrefield(data[0]);
        }
      }
    })
    
  }
  async getFactoryNameDetails(data:any = [], seleectedId:string, isFactory:boolean = false){
   await this.userManagementService.factoryNameDetails(seleectedId).subscribe((res:any) => {
      if(res.success){
        console.log("factory", isFactory)
        if(this.adminData?.userlevel_id !=1 ){
          res.data = res.data.filter((response:any) => this.adminData?.factory_id ? response.id == this.adminData?.factory_id : true)

          let factoryName = res.data.find((factory:any) => factory.id == this.adminData?.factory_id);
          console.log("factory", isFactory, factoryName, this.adminData)
            if(factoryName){
            this.userForm.patchValue({
              factoryName: factoryName,
            })
          }
        }
        if(this.ipNames?.length){
          this.factoryNames = res.data;
        }
        // this.openSnackBar([res?.message || 'Success !'] , '','success');
      }
    },(error:any)=>{
      this.openSnackBar([error?.message || 'Failed !'] , '');
    }, () => {
      if(this.userId && data?.length){
        this.dropdownDataPrefield(data[0]);
      }
    })
  }
 async getUserRoleDetails(){
   let currentRoleId:number = this.adminData?.userlevel_id == 1 ? 0 : this.adminData?.userlevel_id;
   console.log("iduser", this.adminData, currentRoleId)
   await this.userManagementService.UserRoleDetails(currentRoleId).subscribe((res:any) => {
      if(res.success){
        // this.openSnackBar([res?.message || 'Success !'] , '','success');
        this.userRoles = res.data;
      }
    },(error:any)=>{
      this.openSnackBar([error?.message || 'Failed !'] , '');
    })
    
  }

  createUser(){
    if(this.userForm?.invalid){
      this.openSnackBar(['All field are required !!'],'','warning')
    }
    let payload = {
      name : this.userForm?.controls['userName'].value,
      email : this.userForm?.controls['email'].value,
      mobile : this.userForm?.controls['mobile'].value ? this.userForm?.controls['mobile'].value : null,
      country_code: this.userForm?.controls['country_code']?.value ? this.userForm?.controls['country_code']?.value : null,
      country_id : this.showCountryDropdown && this.userForm?.controls['countryName'] ? this.userForm?.controls['countryName'].value?.id : null,
      factory_id : this.showFactoryDropdown && this.userForm?.controls['factoryName'] ? this.userForm?.controls['factoryName'].value?.id : null,
      industrialpark_id : this.showIPdropdown && this.userForm?.controls['ipName'] ? this.userForm?.controls['ipName'].value?.id : null,
      userlevel_id: this.selectedLevel,
      role_id:this.userForm?.controls['userRole'].value?.id,
      is_superuser: this.userForm?.controls['superUser'].value,
      // password : Math.random().toString(36).slice(-8),
      // password : this.userForm?.controls['password'].value,
      createdBy:this.adminData ? this.adminData?.id : '',
    };
    // let country = null;
    // let ipLevel = null;
    // let factoryLevel = null;
    // if(this.showCountryDropdown){
    //   country = {country_id : this.userForm?.controls['countryName'] && this.userForm?.controls['countryName'].value?.id ? this.userForm?.controls['countryName'].value?.id : null,}
      
    // }
    // Object.assign(payload, country);
    // if(this.showIPdropdown){
    //   ipLevel = {industrialpark_id : this.userForm?.controls['ipName'] && this.userForm?.controls['ipName'].value?.id? this.userForm?.controls['ipName'].value?.id : null,}
      
    // }
    // Object.assign(payload, ipLevel);
    // if(this.showFactoryDropdown){
    //   factoryLevel = {factory_id : this.userForm?.controls['factoryName'] && this.userForm?.controls['factoryName'].value?.id ? this.userForm?.controls['factoryName'].value?.id : null,}
    // }
    //   Object.assign(payload, factoryLevel);
    // }

    console.log("payload",payload)
    if(this.userId){
      this.userManagementService.updateUser(this.userId,payload).subscribe((res:any) => {
        if(res.success){
        this.getTargetterUserData(res?.message, payload);
        }
      },(error:any)=>{
        this.openSnackBar([error?.message || 'Failed !'] , '');
      });
    }
    else{
      this.userManagementService.createUser(payload).subscribe((res:any) => {
        if(res.success){
          this.openSnackBar([res?.message || 'Success !'] , '','success');
        }
        this.router.navigate(['/manage-user'])
      },(error:any)=>{
        this.openSnackBar([error?.message || 'Failed !'] ,"");
      });
    }
  }

  getTargetterUserData(respMsg:any, payloadData:any){
    let endpoint = `/admin/submission?data.email=${this.userEmailId}`;
    let payload = {
      "endpoint": endpoint,
      "headers": {
        "Content-Type": "application/json"
      },
      "requestBody": {},
      "method": "GET"
    };
    this.userManagementService.targettedUserData(payload).subscribe((res:any) => {
      if(res.length){
        this.userAllData = res;
        this.updateUserData(this.userAllData, respMsg, payloadData);
      }
    },(error) => console.log(error));
  }

  updateUserData(userData:any, respMsg:any, payloadData:any){
    let endpoint = `/admin/submission/${userData[0]._id}`;
    let payload = {
      "endpoint": endpoint,
      "headers": {
        "Content-Type": "application/json"
      },
      "requestBody": {
        "data": {
          "email": payloadData?.email,
    }
    },
      "method": "PUT"
    }
      this.userManagementService.updateUserForIpsa(payload).subscribe(res => {
        if(res){
          this.openSnackBar([respMsg || 'Created Successfully '] , '','success');
          this.router.navigate(['/manage-user']);
        }
      })
  }

  get f() {
    return this.userForm.controls;
  }
  
  selectRole(selectedValue:any, isFactory:boolean = false){
    console.log(selectedValue);
    console.log("userForm",this.userForm);
    this.selectedLevel = selectedValue?.userlevel_id;
    /* used this switch case as per the defined constant
      ROLE: {
        ADMIN: '1',
        SUB_ADMIN: '2',
        PROGRAM_LEVEL: '3',
        COUNTRY_LEVEL: '4',
        IP_LEVEL: '5',
        PUBLIC_USER: '6',
        FACTORY_LEVEL: '7',
        INSTEP_USER: '8'
      } */
      
      this.userForm.patchValue({
        countryName: '',
        factoryName: '',
        ipName: ''
      })
      this.userForm.controls['factoryName'].clearValidators();
          this.userForm.controls['factoryName'].updateValueAndValidity();

          this.userForm.controls['ipName'].clearValidators();
          this.userForm.controls['ipName'].updateValueAndValidity();

          this.userForm.controls['countryName'].clearValidators();
          this.userForm.controls['countryName'].updateValueAndValidity();
          this.showCountryDropdown = false;
        this.showIPdropdown = false;
        this.showFactoryDropdown = false;
    switch (selectedValue?.userlevel_id) {
      case 1:
        /* setting the values of the form controls `countryName`, `factoryName`, and `ipName` to an empty object. 
        This is typically done to reset the values of these form controls to their initial state or clear their values while changeing the User Role. */
        // this.userForm.controls['countryName'].setValue({});
        // this.userForm.controls['ipName'].setValue({});
        // this.userForm.controls['factoryName'].setValue({});
        
        /* making the values empty until county is being selected */
        this.ipNames = [];   this.factoryNames = [];

        this.showCountryDropdown = false;
        this.showIPdropdown = false;
        this.showFactoryDropdown = false;

        break;
      case 4:
        /* adding new form controls to the `userForm` FormGroup while selecting the Country Level in user Role. */
        // this.userForm.addControl("countryName",new FormControl('', Validators.required));
        // this.userForm.controls['countryName'].setValue({});
        if(this.adminData?.userlevel_id !=1){
          console.log("pictures")
          let countryName = this.countrys.find((country:any) => country.id == this.adminData?.country_id);
          if(countryName){
          this.userForm.patchValue({
            countryName: countryName,
          })
          // let isFactory  = this.adminData?.factory_id ? true : false
          this.getIpNameDetails([],countryName?.id)
        }
          console.log("pictures", this.userForm)
        }
            this.userForm.controls['countryName'].setValidators([Validators.required]);
            this.userForm.controls['countryName'].updateValueAndValidity();

            this.userForm.controls['factoryName'].clearValidators();
          this.userForm.controls['factoryName'].updateValueAndValidity();

          this.userForm.controls['ipName'].clearValidators();
          this.userForm.controls['ipName'].updateValueAndValidity();

        this.showCountryDropdown = true;
        this.showIPdropdown = false;
        this.showFactoryDropdown = false;
        break;
      case 5:
        /* adding new form controls to the `userForm` FormGroup while selecting the IP LEVEL in user Role. */
        // this.userForm.addControl("countryName",new FormControl('', Validators.required));
        // this.userForm.addControl("ipName",new FormControl('', Validators.required));
        // if(this.userForm.controls['factoryName'])
        // this.userForm.controls['factoryName'].setValue({});
  
          // if(this.userForm.controls['countryName']){
            // this.userForm.controls['countryName'].setValue({});

            
            this.userForm.controls['countryName'].setValidators([Validators.required]);
            this.userForm.controls['countryName'].updateValueAndValidity();
            // }
  
            // if(this.userForm.controls['ipName']){
              // this.userForm.controls['ipName'].setValue({});
              this.userForm.controls['ipName'].setValidators([Validators.required]);
              this.userForm.controls['ipName'].updateValueAndValidity();

              this.userForm.controls['factoryName'].clearValidators();
          this.userForm.controls['factoryName'].updateValueAndValidity();

          if(this.adminData?.userlevel_id !=1){
            console.log("pictures")
            let countryName = this.countrys.find((country:any) => country.id == this.adminData?.country_id);
            if(countryName){
            this.userForm.patchValue({
              countryName: countryName,
            })
            this.getIpNameDetails([],countryName?.id,isFactory)
          }
            console.log("pictures", this.userForm)
          }
              // }
        this.factoryNames = [];
        this.showCountryDropdown = true;
        this.showIPdropdown = true;
        this.showFactoryDropdown = false;
        break;
      case 7:
        /* adding new form controls to the `userForm` FormGroup while selecting the Factory LEVEL in user Role. */
        // this.userForm.addControl("countryName",new FormControl('', Validators.required));
        // this.userForm.addControl("ipName",new FormControl('', Validators.required));
        // this.userForm.addControl("factoryName",new FormControl('', Validators.required));


        // if(this.userForm.controls['factoryName']){
          // this.userForm.controls['factoryName'].setValue({});
          this.userForm.controls['factoryName'].setValidators([Validators.required]);
          this.userForm.controls['factoryName'].updateValueAndValidity()
          // }
  
          // if(this.userForm.controls['countryName']){
            // this.userForm.controls['countryName'].setValue({});
            this.userForm.controls['countryName'].setValidators([Validators.required]);
            this.userForm.controls['countryName'].updateValueAndValidity()
            // }
  
            // if(this.userForm.controls['ipName']){
              // this.userForm.controls['ipName'].setValue({});
              this.userForm.controls['ipName'].setValidators([Validators.required]);
              this.userForm.controls['ipName'].updateValueAndValidity()
              // }
              if(this.adminData?.userlevel_id !=1){
                console.log("pictures")
                let countryName = this.countrys.find((country:any) => country.id == this.adminData?.country_id);
                if(countryName){
                this.userForm.patchValue({
                  countryName: countryName,
                })
                // let isFactory  = this.adminData?.factory_id ? true : false
                this.getIpNameDetails([],countryName?.id, isFactory)
              }
                console.log("pictures", this.userForm)
              }
        this.showCountryDropdown = true;
        this.showIPdropdown = true;
        this.showFactoryDropdown = true;
        break;

        case 8:
        /* adding new form controls to the `userForm` FormGroup while selecting the Country Level in user Role. */
        // this.userForm.addControl("countryName",new FormControl('', Validators.required));
        // this.userForm.controls['countryName'].setValue({});
        if(this.adminData?.userlevel_id !=1){
          console.log("pictures")
          let countryName = this.countrys.find((country:any) => country.id == this.adminData?.country_id);
          if(countryName){
          this.userForm.patchValue({
            countryName: countryName,
          })
          // let isFactory  = this.adminData?.factory_id ? true : false
          this.getIpNameDetails([],countryName?.id)
        }
          console.log("pictures", this.userForm)
        }
            this.userForm.controls['countryName'].setValidators([Validators.required]);
            this.userForm.controls['countryName'].updateValueAndValidity();

            this.userForm.controls['factoryName'].clearValidators();
          this.userForm.controls['factoryName'].updateValueAndValidity();

          this.userForm.controls['ipName'].clearValidators();
          this.userForm.controls['ipName'].updateValueAndValidity();

        this.showCountryDropdown = true;
        this.showIPdropdown = false;
        this.showFactoryDropdown = false;
        break;
    
      default:
        /* setting the values of the form controls `countryName`, `factoryName`, and `ipName` to an empty object. 
        This is typically done to reset the values of these form controls to their initial state or clear their values while changeing the User Role. */
        // this.userForm.controls['countryName'].setValue({});
        // this.userForm.controls['ipName'].setValue({});
        // this.userForm.controls['factoryName'].setValue({});

        /* making the values empty until county is being selected */
        this.ipNames = [];   this.factoryNames = [];

        this.showCountryDropdown = false;
        this.showIPdropdown = false;
        this.showFactoryDropdown = false;

        break;
    }
    
  }
  countryChangeHandler(event: MatSelectChange) : void {
    this.selectedCountry = event.value.id;
    console.log("userForm",this.userForm);
    
    if(event?.value?.id){
      this.getIpNameDetails([],event?.value?.id);
      this.ipNames = [];
      this.factoryNames = [];
      this.userForm.patchValue({
        ipName:[],
        factoryName:[]
      })
    }
  }
  iPChangeHandler(event: MatSelectChange) : void {
    if(event?.value?.id){
      
      this.factoryNames = [];
      this.userForm.patchValue({
        factoryName:[]
      })
      this.getFactoryNameDetails([], event?.value?.id);
    }
  }
   async prifieldMethod(userId : string){
     await this.userManagementService.getUserDataById(userId).subscribe((res:any) => {
      if(res.success){
        res = JSON.parse(JSON.stringify(res?.data));
        console.log(res,"123")
        // this.getIpNameDetails(res?.country_id);
        this.userEmailId = res[0]?.email;
        this.selectedLevel = res[0]?.userlevel_id;
        if(res && res[0]['country_id'] && res[0]['industrialpark_id'] && res[0]['factory_id']){
          this.getIpNameDetails(res ,res[0]['country_id']);

          setTimeout(() => {
            this.getFactoryNameDetails(res, res[0]['industrialpark_id']);
          }, 1000);
        }
        else if(res[0]['country_id'] && res[0]['industrialpark_id'] && !res[0]['factory_id']){
          this.getIpNameDetails(res ,res[0]['country_id'])
        }
        else if(res && res[0]['country_id'] && !res[0]['industrialpark_id'] && !res[0]['factory_id']){
            this.dropdownDataPrefield(res[0])
        }else{
          this.dropdownDataPrefield(res[0])
        }
        
         
      }
    },(error:any)=>{
      this.openSnackBar([error?.message || 'Failed !'] ,'','');
    })
  }
  dropdownDataPrefield(data:any){
    console.log(this.userRoles, data,"IPNAMES", this.ipNames);
    
    let countryDetail = this.countrys.find((el:any)=> el.id === data?.country_id);
    let ipDetail = this.ipNames.find((el:any)=> el.id === data?.industrialpark_id);
    let factoryDetail = this.factoryNames.find((el:any)=> el.id === data?.factory_id);
    let roleDetail = this.userRoles.find((el:any)=> el.id === data?.role_id);
    console.log("update",countryDetail,ipDetail,factoryDetail,roleDetail)
    this.selectRole({userlevel_id:data?.userlevel_id})
    // switch (data.role_id) {
    //   case 4:
    //     /* adding new form controls to the `userForm` FormGroup while selecting the Country Level in user Role. */
    //     this.userForm.addControl("countryName",new FormControl('', Validators.required));

    //     this.showCountryDropdown = true;
    //     this.showIPdropdown = false;
    //     this.showFactoryDropdown = false;
    //     break;
    //   case 5:
    //     /* adding new form controls to the `userForm` FormGroup while selecting the IP LEVEL in user Role. */
    //     this.userForm.addControl("countryName",new FormControl('', Validators.required));
    //     this.userForm.addControl("ipName",new FormControl('', Validators.required));
        
    //     this.showCountryDropdown = true;
    //     this.showIPdropdown = true;
    //     this.showFactoryDropdown = false;
    //     break;
    //   case 7:
    //     /* adding new form controls to the `userForm` FormGroup while selecting the Factory LEVEL in user Role. */
    //     this.userForm.addControl("countryName",new FormControl('', Validators.required));
    //     this.userForm.addControl("ipName",new FormControl('', Validators.required));
    //     this.userForm.addControl("factoryName",new FormControl('', Validators.required));

    //     this.showCountryDropdown = true;
    //     this.showIPdropdown = true;
    //     this.showFactoryDropdown = true;
    //     break;
    
    //   default:
    //     /* setting the values of the form controls `countryName`, `factoryName`, and `ipName` to an empty object. 
    //     This is typically done to reset the values of these form controls to their initial state or clear their values while changeing the User Role. */
    //     // this.userForm.controls['countryName'].setValue({});
    //     // this.userForm.controls['factoryName'].setValue({});
    //     // this.userForm.controls['ipName'].setValue({});

    //     /* making the values empty until county is being selected */
    //     this.ipNames = [];   this.factoryNames = [];

    //     this.showCountryDropdown = false;
    //     this.showIPdropdown = false;
    //     this.showFactoryDropdown = false;

    //     break;
    // }
    this.userForm.patchValue({
        ipName: ipDetail,
        countryName: countryDetail,
        factoryName: factoryDetail,
        userRole: roleDetail,
        userName: data.name,
        email:  data.email,
        // password: data.password,
        mobile:  data.mobile,
        country_code: data.country_code,
        superUser: data.is_superuser
    })

    console.log("countrycode", data, this.userForm)
  }
  openSnackBar(message:any, action = '',className='parmary') {
    this.snackBar.open(message, action, {
    duration: 3000,
    // verticalPosition: 'top',
    // horizontalPosition:'right',
    panelClass: [className]
  });
}

numberOnly(event:any): boolean {
  const charCode = (event.which) ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
}

}
