import { AfterViewInit, Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { TncDialogComponent } from "./tnc-dialog/tnc-dialog.component";
import { LogoutConfirmationDialogComponent } from "./logout-confirmation-dialog/logout-confirmation-dialog.component";

@Component({
  selector: "app-tnc",
  templateUrl: "./tnc.component.html",
  styleUrls: ["./tnc.component.scss"],
})
export class TncComponent implements OnInit, AfterViewInit {
  registrationForm!: FormGroup;
  areaOptions: string[] = ["Urban", "Rural", "Suburban"];
  hasAcceptedTerms: boolean = false;
  isSubmitted: boolean = false;

  formFields: any = [
    { name: "name", label: "Name", type: "text", required: true },
    { name: "position", label: "Position", type: "text", required: true },
    {
      name: "email",
      label: "Email",
      type: "email",
      required: true,
      email: true,
    },
    { 
      name: "ip_or_factory",
      label: "Industrial park or factory",
      type: "radio",
      required: true,
      options: [{label: "Industrial Park*", id: 1},{label: "Factory*", id: 2}],
    },
    {
      name: "legal_name",
      label: "Legal IP/Factory Name",
      type: "text",
      required: true,
    },
    {
      name: "registry_name",
      label: "Registry Name",
      type: "text",
      required: true,
    },
    {
      name: "registration_number",
      label: "Registration Number",
      type: "text",
      required: true,
    },
    { name: "street_address", label: "Street", type: "text", required: false },
    { name: "city_or_district", label: "City/District", type: "text", required: true },
    { name: "country", label: "Country", type: "text", required: true },
    {
      name: "postal_code",
      label: "Postal/Zip code",
      type: "number",
      required: false,
    },
    {
      name: "lat",
      label: "Geo codes (Latitude)",
      type: "text",
      required: false,
    },
    {
      name: "lng",
      label: "Geo codes (Longitude)",
      type: "text",
      required: false,
    },
  ];

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public tncFormDialogRef: MatDialogRef<TncComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    console.log('Data From: ', this.data);
    
    this.registrationForm = this.formBuilder.group(
      this.formFields.reduce((acc: any, field: any) => {
        acc[field.name] = field.required
          ? [
              "",
              field.email
                ? [Validators.required, Validators.email]
                : Validators.required,
            ]
          : [""];
        return acc;
      }, {})
    );

  }

  ngAfterViewInit(): void {
    this.registrationForm?.get('email')?.patchValue(this.data?.dataFrom?.username);
    this.registrationForm?.get('email')?.disable();
  }

  register() {
    if (this.registrationForm.valid) {
      console.log("Registration form: ", this.registrationForm.value);

      const formData = this.registrationForm.getRawValue();

      let data = {
        name: formData.name,
        position: formData.position,
        email: formData.email,
        ip_or_factory: formData.ip_or_factory.toString(), 
        legal_name: formData.legal_name,
        registry_name: formData.registry_name,
        registration_number: formData.registration_number,
        street_address: formData.street_address,
        city_or_district: formData.city_or_district,
        country: formData.country,
        postal_code: parseInt(formData.postal_code),
        geo_code: {
          latitude: parseFloat(formData.lat),
          longitude: parseFloat(formData.lng),
        },
        agreedToTerms: true,
        industrialpark_id: formData.ip_or_factory === 1 ? 1 : null,
        factory_id: formData.ip_or_factory === 2 ? 1 : null,
      };

      this.tncFormDialogRef.close(data);
    } else {
      console.log("Form is invalid");
      this.isSubmitted = true;
    }
  }

  openLogoutDialog() {
    const dialogRef = this.dialog.open(LogoutConfirmationDialogComponent);
    dialogRef.afterClosed().subscribe((result: any) => {
      console.log("Dialogue Result: ", result);
      if (result) {
        this.onClose();
      }
    });
  }

  onClose(): void {
    this.tncFormDialogRef.close();
  }
 

  openTncDialog(): void {
    const dialogRef = this.dialog.open(TncDialogComponent, {
      width: "800px",
      disableClose: true,
      data: { message: "Hello from App Component!" },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The Tnc dialog was closed: ", result);
      if (result == true) {
        this.hasAcceptedTerms = result;
      }
    });
  }
}
