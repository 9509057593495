import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-tnc-dialog',
  templateUrl: './tnc-dialog.component.html',
  styleUrls: ['./tnc-dialog.component.scss']
})
export class TncDialogComponent implements OnInit {


  constructor(
    public dialogRef: MatDialogRef<TncDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }

  
  onClose(data: boolean): void {
      this.dialogRef.close(data);
  }

  downloadPDF(): void {
    const link = document.createElement('a');
    link.href = '../../../../assets/tnc_pdf/INSTEP Portal Terms and Conditions of Use.pdf';
     // Update this path to match your asset folder structure
    link.download = 'INSTEP Portal Terms and Conditions of Use.pdf';
    link.click();
  }
}
