import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
// import { AccessibilityComponent } from './pages/accessibility/accessibility.component';
import { HistoricalSitesComponent } from './pages/historical-sites/historical-sites.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { DocumentsComponent } from './pages/documents/documents.component';
import { LoginComponent } from './pages/login/login.component';
import { ImpactStoriesComponent } from './pages/impact-stories/impact-stories.component';
import { ResourcesComponent } from './pages/resources/resources.component';
import { RelatedStoriesComponent } from './pages/related-stories/related-stories.component';
import { ManagePortalComponent } from './admin/manage-portal/manage-portal.component';
import { SubscribersComponent } from './admin/subscribers/subscribers.component';
import { ResourcesViewComponent } from './pages/resources-view/resources-view.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AdminFormsComponent } from './admin/admin-forms/admin-forms.component';
import { ProgramFormComponent } from './admin/admin-forms/program-form/program-form.component';
import { AuthGuard } from './auth.guard';
import { OurpartnersComponent } from './pages/ourpartners/ourpartners.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { BaselineFormComponent } from './admin/admin-forms/baseline-form/baseline-form.component';
import { BaselineMonthlyFormComponent } from './admin/admin-forms/baseline-monthly-form/baseline-monthly-form.component';
import { FormListComponent } from './admin/admin-forms/form-list/form-list.component';
import { CommunicationsComponent } from './admin/communications/communications.component';
import { UserProfileComponent } from './admin/user-profile/user-profile.component';
import { NotificationsComponent } from './admin/notifications/notifications.component';
import { ManageUserComponent } from './admin/user-management/manage-user/manage-user.component';
import { UserLevelComponent } from './admin/user-management/user-level/user-level.component';
import { AddUserComponent } from './admin/user-management/add-user/add-user.component';
import { UserPoliciesComponent } from './admin/user-management/user-policies/user-policies.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { FormBuilderComponent } from './pages/form-builder/form-builder.component';
import { IpsaFormsComponent } from './pages/ipsa-forms/ipsa-forms.component';
import { FormListComponentIPSA } from './pages/ipsa-forms/form-list/form-list.component';
import { IpsaFormDesignComponent } from './pages/ipsa-forms/ipsa-form-design/ipsa-form-design.component';
import { IpsaDashboardComponent } from './pages/ipsa-dashboard/ipsa-dashboard.component';
import { IpsaIntroComponent } from './pages/ipsa-intro/ipsa-intro.component';
import { IpsaComponent } from './pages/ipsa-forms/ipsa/ipsa.component';
import { IpsaReportComponent } from './pages/ipsa-forms/ipsa-report/ipsa-report.component';
import { IndicatorPermissionComponent } from './admin/user-management/indicator-permission/indicator-permission.component';
// import { TncComponent } from './pages/tnc/tnc.component';


const routes: Routes = [

	{
		path: "home",
        component: HomeComponent,
	},
	{
		path: "",
		redirectTo:"home",
		pathMatch: 'full'
	},
  // {
  //   path: "accessibility",
  //   component: AccessibilityComponent
  // },
  // {
  //   path: "historical-sites",
  //   component: HistoricalSitesComponent
  // },
  {
    path: "about-us",
    component: AboutUsComponent
  },
  {
    path: "communications",
    component: CommunicationsComponent
  },
  {
    path: "user-profile",
    component: UserProfileComponent
  },
  {
    path: "notifications",
    component: NotificationsComponent
  },
  // {
  //   path: "documents",
  //   component: DocumentsComponent
  // },
  {
    path: "resources",
    component: ResourcesComponent
  },
  {
    path: "resources-view",
    component: ResourcesViewComponent
  },
  {
    path: "impact-stories",
    component: ImpactStoriesComponent
  },
  {
    path: "about-us",
    component: AboutUsComponent
  },
  {
    path: "related-stories",
    component: RelatedStoriesComponent
  },
  {
    path: "login",
    component: LoginComponent
  },
  {
    path: "forgot-password",
    component: ForgotPasswordComponent
  },
  {
    path: "reset-password/:id",
    component: ResetPasswordComponent
  },
  {
    path: "sign-up",
    component: SignUpComponent
  },
  {
    path: "ourpartners",
    component: OurpartnersComponent
  },
  {
    path: "ipsa-report",
    component: IpsaReportComponent
  },
  {
    path: "manage-portal",
    canActivate:[AuthGuard],
    component: ManagePortalComponent
  },
  {
    path: "admin-forms",
    canActivate:[AuthGuard],
    component: AdminFormsComponent
  },
  {
    path: "ipsa",
    // canActivate:[AuthGuard],
    component: IpsaComponent
  },
  {
    path: "ipsa-forms",
    // canActivate:[AuthGuard],
    component: IpsaFormsComponent
  },
  {
    path: "ipsa-forms-list",
    // canActivate:[AuthGuard],
    component: FormListComponentIPSA
  },
  {
    path: "ipsa-forms-design",
    // canActivate:[AuthGuard],
    component: IpsaFormDesignComponent
  },
  {
    path: "program-form",
    canActivate:[AuthGuard],
    component: ProgramFormComponent
  },
  {
    path: "baseline-form",
    canActivate:[AuthGuard],
    component: BaselineFormComponent
  },
  {
    path: "baseline-monthly-form",
    canActivate:[AuthGuard],
    component: BaselineMonthlyFormComponent
  },
  {
    path: "subscribers",
    canActivate:[AuthGuard],
    component: SubscribersComponent
  },
  {
    path: "dashboard",
    // canActivate:[AuthGuard],
    component: DashboardComponent
  },
  {
    path: "form-builder",
    // canActivate:[AuthGuard],
    component: FormBuilderComponent
  },
  {
    path: "forms-list",
    canActivate:[AuthGuard],
    component: FormListComponent
  },
  {
    path: "manage-user",
    // canActivate:[AuthGuard],         //Hotfix as we need to chage the Authgaurd rule
    component: ManageUserComponent
  },
  {
    path: "manage-dashboard",
    component: IndicatorPermissionComponent
  },
  {
    path: "user-level",
    // canActivate:[AuthGuard],         //Hotfix as we need to chage the Authgaurd rule
    component: UserLevelComponent
  },
  {
    path: "add-user",
    canActivate:[AuthGuard],
    component: AddUserComponent
  },
  {
    path: "user-policies",
    canActivate:[AuthGuard],
    component: UserPoliciesComponent
  },
  {
    path: "user-policies/:id",
    canActivate:[AuthGuard],
    component: UserPoliciesComponent
  },
  {
    path:'add-user/:id',
    canActivate:[AuthGuard],
    component: AddUserComponent

  },
  {
    path: "ipsa-dashboard",
    // canActivate:[AuthGuard],
    component: IpsaDashboardComponent
  },
  {
    path: "ipsa-intro",
    // canActivate:[AuthGuard],
    component: IpsaIntroComponent
  },
  {
    path: "",
    // canActivate: [AuthGuard],
    loadChildren: () => import('./admin/manage-portal/manage-portal.module').then(m => m.ManagePortalModule),
  },
  {
    path:'**',
    redirectTo:'/'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
