<div class="Footer_bottom">
  <section>
  <div class="text-right mb-2">
    <button mat-icon-button (click)="openLogoutDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <form [formGroup]="registrationForm" style="margin: 0px 20px 10px 20px">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-md-4" *ngFor="let field of formFields">
            <mat-form-field
              appearance="outline"
              class="w-100 p-0 mb-1"
              *ngIf="field.type !== 'radio'"
            >
              <mat-label
                >{{ field.label }} {{ field.required ? "*" : "" }}</mat-label
              >
              <input
                matInput
                [type]="field.type"
                [formControlName]="field.name"
                [placeholder]="field.placeholder"
              />
              <mat-error
                *ngIf="
                  registrationForm.get(field.name)?.invalid &&
                  registrationForm.get(field.name)?.touched
                "
              >
                {{ field.label }} is required.
              </mat-error>
            </mat-form-field>

            <!-- Radio Buttons -->
            <div *ngIf="field.type === 'radio'">
              <div
                class="radio-group-container"
                [class.invalid]="
                  registrationForm.get(field.name)?.invalid && isSubmitted"
              >
                <mat-radio-group
                  [formControlName]="field.name"
                  class="d-flex flex-row"
                >
                  <mat-radio-button
                    style="margin-top: 8px"
                    *ngFor="let option of field?.options"
                    [value]="option.id"
                  >
                    {{ option.label }}
                  </mat-radio-button>
                </mat-radio-group>
              </div>
              <mat-error
                *ngIf="
                  registrationForm.get(field.name)?.invalid &&
                  registrationForm.get(field.name)?.touched
                "
              >
                {{ field.label }} is required.
              </mat-error>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <span
              >Please
              <span
                (click)="openTncDialog()"
                style="color: blue; cursor: pointer; text-decoration: underline"
                >click here</span
              >
              to read and accept the Terms and Conditions of Use</span
            >
          </div>
          <div class="col-md-6 text-end">
            <button
              class="btn create-btn rounded-pill text-white"
              [disabled]="!hasAcceptedTerms"
              (click)="register()"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
  </section>
</div>
