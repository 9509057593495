import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { HeaderComponent } from 'src/app/layout/header/header.component';
import { CommonServiceService } from "../services/common-service.service";
import { SignUpComponent } from '../sign-up/sign-up.component';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { TncComponent } from '../tnc/tnc.component';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  show: any;
  isEmail: boolean = false;
  constructor(public dialogRef: MatDialogRef<HeaderComponent>, public dialog: MatDialog, private commonservice: CommonServiceService, public router: Router, private snackBar: MatSnackBar) { }
  username: any = "";
  password: any = "";
  imageCaptcha : string = ''
  captcha :any ;
  loginFailedDueToCaptcha :string = '';
  captcha_id : string =''
  loginMessage:string[] = ['Invalid credential','Incorrect captcha']
  ngOnInit(): void {
    this.getNewCaptcha()
  }

  close(){
    this.dialogRef.close();
  }

  showPassword(){
    this.show = !this.show
  }

  loginuser(){

    // this.router.navigate(['/terms&conditions'])
    // return;

    if(this.username == "" && this.password == ""){
      this.openSnackBar(['Please Enter Username and Password !!'])
      return;
    }
    if(this.username == "" ){
      this.openSnackBar(['Please Enter Username !!'])
      return;
    }
    if( this.password == ""){
      this.openSnackBar(['Please Enter  Password !!'])
      return;
    }
    if(!this.captcha){
      this.openSnackBar(['Please Enter captcha !!'])
      return;
    }
    
    const random = Math.random();
    let password = this.password;
    let cipherText = this.commonservice.getSecratKey(environment.secratKey,password);
    let randomKey = random.toString();
    let encryptCiphertextRandom = this.commonservice.getSecratKey(randomKey, cipherText);
   let data = {
        "username": this.username,
        "password": encryptCiphertextRandom,
        random : randomKey,
        captcha_id:this.captcha_id,
        captchaText:this.captcha
      };

    this.commonservice.loginService(data).subscribe(async (res:any) => {
      console.log("Login Response: ",res);
      
      if(res.success){
        if (res.data.tnc) {
          this.openTncFormDialog(data);
          return
        }
        // this.openSnackBar(res.message,'',true)
        // this.dialogRef.close(true);
        let responseData = JSON.parse(JSON.stringify(res))
        sessionStorage.setItem('userdata', JSON.stringify(res?.data));
        sessionStorage.setItem('token', res['token']);
        // this.router.navigate(['/manage-portal']);
        // let x:any = await this.loginToFormIO()
        this.loginToFormIO().then((x:any) => {
          let token = x.body['x-jwt-token'];
            sessionStorage.setItem('jwtToken', token);
            console.log("allformIOresp",JSON.stringify(x));
            
            console.log("tokennn", token);
        // })
            
            
        let getValue:any = JSON.parse(sessionStorage.getItem('userdata')!);
        console.log("getValue",getValue, typeof(getValue));
        sessionStorage.removeItem('captcha_id');
        this.getDistroyCaptcha(false);

        // responseData?.data?.role_id ? this.permissonUserList(responseData?.data?.role_id): this.openSnackBar(['User Permission not allowed ']);
        if(responseData?.data?.role_id){
          this.permissonUserList(responseData?.data?.role_id).then((result:any) => {
            console.log("LoginComponent", result)
            if(result == 'succes'){
              // this.openSnackBar(res.message,'',true)
              this.dialogRef.close({isLoggedIn: true, message: res?.message});
            // this.router.navigate(['/dashboard'])
            }
          })
        }else{
          this.openSnackBar(['User Permission not allowed '])
        }
      })
        
        // Calling Form IO Login API
          
        // this.checkFooter(getValue);
      }
    },(error) => {
      const messageCheck: string = 'Your login is currently restricted.'
      if (error.error.message.includes(messageCheck)) {
        this.openSnackBar(error.error.message, '', false, 6000);
        
      } else{
        this.openSnackBar(error.error.message, '', false, 3000);
      }
      let isInvalidCredtional =  this.loginMessage.includes(error.error.message)
      this.getDistroyCaptcha(isInvalidCredtional);
      this.refreshCaptcha();
    })
  }

   myHeaders = new Headers();

  async loginToFormIO(){
    let logindata = {
      'method': 'POST',
      'endpoint': '/user/login',
      'headers': {
        'Content-Type': 'application/json'
      },
      requestBody: {
        "data": {
          "email": this.username,
          "password": this.password
        }
      }
    };
    return this.commonservice.loginFormIOservice(logindata).toPromise();
  }

  checkFooter(footerStatus:any){
    this.commonservice.updateResouceCount(footerStatus);
  }

  openSnackBar(message:any, action = '', isSuuces:boolean=false, duration: number = 3000) {
    this.snackBar.open(message, action, {
      duration: duration,
      // verticalPosition: 'top',
      // horizontalPosition:'right',
      panelClass: !isSuuces ? ['primary'] : ''
    });
  }

openSignUpForm(){
  this.router.navigate(['home'])
  let dialogRefcomp = this.dialog.open(SignUpComponent, {
    width: '750px',
    panelClass: 'signUp-dialog-class',
    disableClose: true,
    // position: {top: '120px'},
  });
  this.dialogRef.close();
}

openForgotDialog(){
  this.router.navigate(['forgot-password'])

  // this.router.navigate(['home'])
  // let dialogRefcomp = this.dialog.open(ForgotPasswordComponent, {
  //   width: '450px',
  //   panelClass: 'signUp-dialog-class',
  //   disableClose: true,
  //   position: {top: '160px'},
  // });
  this.dialogRef.close();
}
refreshCaptcha() {
  let captcha:any = sessionStorage.getItem('captcha_id');
  captcha = JSON.parse(captcha)
  console.log("cap",captcha)
  this.commonservice.refreshCaptch(captcha).subscribe((res:any)=>{
    if(res.success){
      this.captcha_id = '';
     this.getNewCaptcha();
    }
  },(error:any)=>{
    this.openSnackBar(error?.error?.message)
  })
}
getNewCaptcha() {
  this.commonservice.captchaData().subscribe((res: any) => {
    console.log(res);
    if(res.success){
      this.imageCaptcha = res?.data?.captcha;
      sessionStorage.setItem('captcha_id',JSON.stringify(res?.data?.id))
      this.captcha_id = res?.data?.id;
    }
  },(error:any)=>{
    this.openSnackBar(error?.error?.message)
  })

}
getDistroyCaptcha(isDistroy:boolean=false,){
  this.commonservice.destroyRefreshCaptcha(this.captcha_id).subscribe((res:any)=>{
    if(res.success){
      this.captcha_id = '';
      this.imageCaptcha = ''
      this.captcha = ''
      isDistroy ? this.getNewCaptcha() : ''
    }
  })
}

async permissonUserList(roleId:string){
  return new Promise((resolve, reject) => {
    this.commonservice.getRoleDataById(roleId).subscribe((res:any)=>{
      res = JSON.parse(JSON.stringify(res.data))
      console.log("session",res)
      if(res){
        sessionStorage.setItem('userPermission',JSON.stringify(res));
        
        resolve('succes');
        // window.location.reload();
        // this.router.navigate(['/dashboard'])
       
        
        // this.landingPageWithAuthentication(res[0]['authenicationJson'])
        
        
      }
    },(error) => {
      this.dialogRef.close(false);
      console.log(error,error.error.message,"err")
      this.openSnackBar(error.error.message);
      reject('error');
    })
  })
}
landingPageWithAuthentication(data:any){
  let module = data.find((el:any)=>el?.model === 'cms');
  let isManagePortal:boolean = module.some((el:any)=>(el?.is_checked || el?.isAction) || el?.authentication.some((e:any)=>e?.isAction))
  console.log(isManagePortal,"isManagePortal")
  isManagePortal ? this.router.navigate(['/manage-portal']) : this.router.navigate(['/home']);
}
validateMethod(){
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if(emailRegex.test(this.username) || !this.username){
      this.isEmail = false;
    }
    else{
      this.isEmail = true;
    }
}
// passwordValidator() {
//   const value = this.password;
//   const hasNumber = /[0-9]/.test(value);
//   const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);
//   const hasUpperCase = /[A-Z]/.test(value);
//   const hasLowerCase = /[a-z]/.test(value);
//   const isLengthValid = value.length >= 8;
//   this.isPasswordValid = hasNumber && hasSpecialChar && hasUpperCase && hasLowerCase && isLengthValid;
//   // console.log("isValid",isValid)
//   // return isValid ? null : { invalidPassword: true };
// }

openTncFormDialog(data: any): void {
  const dialogRef = this.dialog.open(TncComponent, {
    width: '100vw',
    height: '80vh',
    disableClose: true,
    data: { dataFrom: data }
  });

  dialogRef.afterClosed().subscribe(result => {
    if (result) {
      console.log('Dialog result Payload Data: ', result);
      this.commonservice.loginService(result).subscribe(async (res:any) => {
        console.log("Login Response: ",res);
        
        if(res.success){
          // this.openSnackBar(res.message,'',true)
          // this.dialogRef.close(true);
          let responseData = JSON.parse(JSON.stringify(res))
          sessionStorage.setItem('userdata', JSON.stringify(res?.data));
          sessionStorage.setItem('token', res['token']);
          // this.router.navigate(['/manage-portal']);
          // let x:any = await this.loginToFormIO()
          this.loginToFormIO().then((x:any) => {
            let token = x.body['x-jwt-token'];
              sessionStorage.setItem('jwtToken', token);
              console.log("allformIOresp",JSON.stringify(x));
              
              console.log("tokennn", token);
          // })
              
              
          let getValue:any = JSON.parse(sessionStorage.getItem('userdata')!);
          console.log("getValue",getValue, typeof(getValue));
          sessionStorage.removeItem('captcha_id');
          this.getDistroyCaptcha(false);
  
          // responseData?.data?.role_id ? this.permissonUserList(responseData?.data?.role_id): this.openSnackBar(['User Permission not allowed ']);
          if(responseData?.data?.role_id){
            this.permissonUserList(responseData?.data?.role_id).then((result:any) => {
              console.log("LoginComponent", result)
              if(result == 'succes'){
                // this.openSnackBar(res.message,'',true)
                this.dialogRef.close({isLoggedIn: true, message: res?.message});
              // this.router.navigate(['/dashboard'])
              }
            })
          }else{
            this.openSnackBar(['User Permission not allowed '])
          }
        })
          
          // Calling Form IO Login API
            
          // this.checkFooter(getValue);
        }
      },(error) => {
        this.openSnackBar(error.error.message);
        let isInvalidCredtional =  this.loginMessage.includes(error.error.message)
        this.getDistroyCaptcha(isInvalidCredtional);
        this.refreshCaptcha();
      })
    } else {
      console.log('Dialog was closed without submitting.');
    }
  });
}

}
