<div class="Footer_bottom">
  <section>
    <div class="container-fluid">
      <div class="my-3 d-flex align-items-center">
        <span class="pointer" routerLink="/manage-user">
          <svg class="me-3" id="keyboard_backspace_black_24dp_3_" data-name="keyboard_backspace_black_24dp(3)"
            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path id="Path_7892" data-name="Path 7892" d="M0,0H24V24H0Z" fill="none" />
            <path id="Path_7893" data-name="Path 7893" d="M21,11H6.83l3.58-3.59L9,6,3,12l6,6,1.41-1.41L6.83,13H21Z"
              fill="black" />
          </svg>
        </span>
  
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item fs-18"><a class="text-decoration-none" routerLink="/manage-user"
                style="color: #949494;">Portal Management</a></li>
            <li class="breadcrumb-item fs-18"><a class="text-decoration-none text-dark fw-500">{{userId ? 'Edit User' : 'Add User' }}</a></li>
          </ol>
        </nav>
      </div>
  
  
      <form  [formGroup]="userForm">
        <div class="row">
          <div class="col-12">
            <div class="card border-0 mb-3 shadow-sm">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4">
                    <mat-form-field appearance="outline" class="example-full-width w-100 p-0 mb-1">
                      <mat-label>Name *</mat-label>
                      <input matInput type="text" formControlName="userName" placeholder="Enter Name" [ngClass]="{'is-invalid':userForm.get('userName')?.touched && userForm.get('userName')?.invalid}" appAlphabatesOnly [readonly]="currentMode == 'view'">
                    </mat-form-field>
                    <span class="invalid-feedback" *ngIf="(userForm.get('userName')?.touched  || userForm.get('userName')?.dirty) && userForm.get('userName')?.invalid">
                      Name is required *.
                   </span>
                  </div>
                  <div class="col-md-4">
                    <mat-form-field appearance="outline" class="example-full-width w-100 p-0 mb-1">
                      <mat-label>Email *</mat-label>
                      <input matInput type="email" formControlName="email" [ngClass]="{'is-invalid':userForm.get('email')?.touched && userForm.get('email')?.invalid}" placeholder="example@gmail.com" [readonly]="currentMode == 'view'">
                    </mat-form-field>
                    <mat-error class="invalid-feedback mx-3 mb-0" *ngIf="userForm.get('email')?.touched && userForm.get('email')?.invalid">
                      <ng-container *ngIf="userForm.get('email')?.errors?.required">
                        Email is required *.
                      </ng-container>
                      <ng-container *ngIf="userForm.get('email')?.errors?.email && !userForm.get('email')?.errors?.required">
                        Please enter a valid email.
                      </ng-container>
                    </mat-error>
                  </div>
                  <div class="col-md-4 row" [ngStyle]="{'pointer-events': currentMode == 'view' ? 'none' : 'auto'}">
                    <mat-form-field appearance="outline" class="example-full-width col-md-4">
                      <mat-label>Select Country Code</mat-label>
                      <mat-select formControlName="country_code" >
                        <mat-option [value]="item?.code" *ngFor="let item of countryCodeArray">
                          <span class="w-50">+{{item?.code}}</span>
                          <span class="w-50">&nbsp; &nbsp; &nbsp; &nbsp; | &nbsp; {{item?.name}}</span>
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                      <!-- <mat-error class="invalid-feedback  mt-0"
                    *ngIf="(userForm.get('country_code')?.touched  || userForm.get('country_code')?.dirty) && userForm.get('country_code')?.invalid">
                    Country Code is Required *.
                  </mat-error> -->
                    
                    <mat-form-field appearance="outline" class="example-full-width col-md-8 p-0 mb-1">
                      <mat-label>Phone Number</mat-label>
                      <input matInput type="text" placeholder="Enter Number" maxlength="12" formControlName="mobile" [ngClass]="{'is-invalid':userForm.get('mobile')?.touched && userForm.get('mobile')?.invalid}" (keypress)="numberOnly($event)" [readonly]="currentMode == 'view'">
                    </mat-form-field>
                    <!-- <mat-error class="invalid-feedback" *ngIf="(userForm.get('mobile')?.touched  || userForm.get('mobile')?.dirty) && userForm.get('mobile')?.invalid">
                      Phone Number is Required *.
                   </mat-error> -->
                  </div>
                  <div class="col-md-4" [ngStyle]="{'pointer-events': currentMode == 'view' ? 'none' : 'auto'}">
                    <mat-form-field appearance="outline" class="w-100 p-0 mb-1">
                      <mat-label>User Role *                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                </mat-label>
                      <mat-select formControlName="userRole" placeholder="Select User Role" [ngClass]="{'is-invalid':userForm.get('userRole')?.touched && userForm.get('userRole')?.invalid}" (selectionChange)="selectRole($event.value, true)" #userRole (keydown)="preventKeyboardInput($event)">
                        <mat-option *ngFor="let userRole of userRoles" [value]="userRole" >
                          {{userRole?.name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-error class="invalid-feedback" *ngIf="(userForm.get('userRole')?.touched  || userForm.get('userRole')?.dirty) && userForm.get('userRole')?.invalid">
                      User Role is required *.
                   </mat-error>
                  </div>

                  <!-- <div class="col-md-4">
                    <mat-form-field appearance="outline" class="example-full-width w-100 p-0 mb-1">
                      <mat-label>Password *</mat-label>
                      <input matInput type="password" formControlName="password" placeholder="Enter Password" [ngClass]="{'is-invalid':userForm.get('password')?.touched && userForm.get('password')?.invalid}" >
                    </mat-form-field>
                    <span class="invalid-feedback" *ngIf="(userForm.get('password')?.touched  || userForm.get('password')?.dirty) && userForm.get('password')?.invalid">
                      Password is Required *.
                   </span>
                  </div> -->
                  
                  <div class="col-md-4" *ngIf="showCountryDropdown">
                    <mat-form-field appearance="outline" class="w-100 p-0 mb-1">
                      <mat-label>Country Name *</mat-label>
                      <mat-select formControlName="countryName" [ngClass]="{'is-invalid':userForm.get('countryName')?.touched && userForm.get('countryName')?.invalid}" (selectionChange)="countryChangeHandler($event)" >
                        <mat-select-trigger *ngIf="userForm?.value?.countryName">
                          <img [src]="userForm.value?.countryName?.country_icons " alt="Image" class="option-image">
                          <span>{{userForm.value?.countryName?.name}}</span>
                        </mat-select-trigger>
                        <mat-option *ngFor="let country of countrys" [value]="country">
                          <img [src]="country?.country_icons" alt="Image" class="option-image">
                          {{country?.name}}
                        </mat-option>
                      </mat-select>
                     
                    </mat-form-field>
                    <mat-error class="invalid-feedback" *ngIf="(userForm.get('countryName')?.touched  || userForm.get('countryName')?.dirty) && userForm.get('countryName')?.invalid">
                      Country Name is required *.
                   </mat-error>
                  </div>
                  <div class="col-md-4" *ngIf="showIPdropdown">
                    <mat-form-field appearance="outline" class="w-100 p-0 mb-1">
                      <mat-label>IP NAME *</mat-label>
                      <mat-select formControlName="ipName" [ngClass]="{'is-invalid':userForm.get('ipName')?.touched && userForm.get('ipName')?.invalid}" (selectionChange)="iPChangeHandler($event)" [disabled]="!ipNames?.length">
                        <mat-option *ngFor="let ipName of ipNames" [value]="ipName">
                          {{ipName?.name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-error class="invalid-feedback" *ngIf="(userForm.get('ipName')?.touched  || userForm.get('ipName')?.dirty) && userForm.get('ipName')?.invalid">
                      IP Name is required *.
                   </mat-error>
                  </div>
                  <div class="col-md-4" *ngIf="showFactoryDropdown">
                    <mat-form-field appearance="outline" class="w-100 p-0 mb-1">
                      <mat-label>Factory Name *</mat-label>
                      <mat-select formControlName="factoryName" [ngClass]="{'is-invalid':userForm.get('factoryName')?.touched && userForm.get('factoryName')?.invalid}" [disabled]="!factoryNames?.length">
                        <mat-option *ngFor="let factoryName of factoryNames" [value]="factoryName">
                          {{factoryName?.name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-error class="invalid-feedback" *ngIf="(userForm.get('factoryName')?.touched  || userForm.get('factoryName')?.dirty) && userForm.get('factoryName')?.invalid">
                      Factory Name is required *.
                   </mat-error>
                  </div>
                  
    
                  <div class="text-end">
                    <mat-checkbox formControlName="superUser" class="super-user" *ngIf="showIPdropdown || showFactoryDropdown">Make Super User</mat-checkbox>
                    <button *ngIf="!userId" class="btn create-btn rounded-pill text-white" (click)="createUser()" [disabled]="userForm.invalid">Create</button>
                    <button *ngIf="userId && currentMode != 'view'" type="submit" class="btn create-btn rounded-pill text-white" (click)="createUser()" [disabled]="userForm.invalid">Update</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      
    </div>
  </section>
</div>